export const API_URL = process.env.REACT_APP_API_URL;

export default {
    AUTH: `${API_URL}/api/token/`,
    PASSWORD_RESET: `${API_URL}/api/password-reset/`,
    PASSWORD_CONFIRM: `${API_URL}/api/password-reset-confirm/`,
    PROFILE: `${API_URL}/api/user/profile/`,
    USER: `${API_URL}/api/user/`,
    OPTIK: `${API_URL}/api/optik/`,
    OPTIK_GROUP: `${API_URL}/api/optik-group/`,
    OPTIK_GROUP_CHILD: (groupId) => `${API_URL}/api/optik-group/${groupId}/optik`,
    COLOR: `${API_URL}/api/lens-color/`,
    COATING: `${API_URL}/api/lens-coating/`,
    INDEKS: `${API_URL}/api/lens-index/`,
    LENS_GROUP: `${API_URL}/api/product-lens-group/`,
    LENSA: `${API_URL}/api/product-lens/`,
    CREATE_OPTIK: `${API_URL}/api/optik/create/`,
    CREATE_CUSTOMER: `${API_URL}/api/user/create/`,
    UPDATE_CUSTOMER: `${API_URL}/api/user/update/`,
    ORDER: `${API_URL}/api/order/`,
    PATTERN: `${API_URL}/api/pattern/`,
    TINTING_COLOR: `${API_URL}/api/lens-tinting-color/`,
    DENSITY: `${API_URL}/api/lens-tinting-density/`,
    TINTING_GROUP: `${API_URL}/api/lens-tinting-density-group/`,
    CORRIDOR: `${API_URL}/api/corridor/`,
    BATCH_ORDER: `${API_URL}/api/order/`,
    BULK_PRODUCTION: `${API_URL}/api/order/bulk-production/`,
    BULK_COMPLETED: `${API_URL}/api/order/bulk-completed/`
};
