import { createSlice } from '@reduxjs/toolkit';
import endpoints from 'utils/endpoints';
import axios from 'utils/axios';
import showMessagePopUp from 'ui-component/messagePopUp';
import standardParams from 'utils/standardParams';
import { PAGE_SIZE_DEFAULT } from 'utils/table';

const initialState = {
    isLoading: false,
    error: null,
    listOfOrder: {
        count: 0,
        data: []
    },
    viewOrder: {
        status: 'ON_REVIEW'
    },
    sentVo: {
        isLoading: false,
        error: null
    },
    batchOrder: {
        isLoading: false,
        error: null
    },
    updateOrder: {
        isLoading: false,
        success: false
    }
};

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        getOrdersSuccess: (state, action) => {
            state.isLoading = false;
            state.listOfOrder = action.payload;
        },
        getViewOrderSuccess: (state, action) => {
            state.isLoading = false;
            state.viewOrder = action.payload;
        },
        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        updatingOrder: (state) => {
            state.updateOrder.isLoading = true;
            state.updateOrder.success = false;
        },
        updatingOrderSuccess: (state) => {
            state.updateOrder.isLoading = false;
            state.updateOrder.success = true;
        },
        updatingOrderFail: (state) => {
            state.updateOrder.isLoading = false;
            state.updateOrder.success = false;
        },
        startingBatchOrder: (state) => {
            state.batchOrder.isLoading = true;
            state.batchOrder.error = null;
        },
        batchOrderSuccess: (state) => {
            state.batchOrder.isLoading = false;
            state.batchOrder.error = null;
        },
        batchOrderError: (state, action) => {
            state.batchOrder.isLoading = false;
            state.batchOrder.error = action.payload;
        },
        startingSentVo: (state) => {
            state.sentVo.isLoading = true;
            state.sentVo.error = false;
        },
        sentVoSuccess: (state) => {
            state.sentVo.isLoading = false;
            state.sentVo.error = false;
        },
        sentVoError: (state, action) => {
            state.sentVo.isLoading = false;
            state.sentVo.error = action.payload;
        }
    }
});

export const actions = orderSlice.actions;

export function getOrders(params) {
    return async (dispatch) => {
        const url = endpoints.ORDER;
        const config = {
            method: 'get',
            params
        };

        dispatch(orderSlice.actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(orderSlice.actions.getOrdersSuccess(response));
        } catch (error) {
            dispatch(orderSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function getOrder(orderId) {
    return async (dispatch) => {
        const url = `${endpoints.ORDER + orderId}/`;
        const config = {
            method: 'get'
        };

        dispatch(orderSlice.actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(orderSlice.actions.getViewOrderSuccess(response));
        } catch (error) {
            dispatch(orderSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function updateOrder(orderId, data, fallback) {
    return async (dispatch) => {
        const url = `${endpoints.ORDER + orderId}/`;
        const config = {
            method: 'put',
            data
        };

        dispatch(orderSlice.actions.updatingOrder());

        try {
            const { data: response } = await axios(url, config);
            dispatch(orderSlice.actions.updatingOrderSuccess());
            fallback(response, null);

            window.scrollTo({ top: 0, behavior: 'smooth' });
        } catch (error) {
            dispatch(orderSlice.actions.updatingOrderFail());

            fallback(null, error);

            if (error?.response?.status === 400) {
                const errors = error?.response?.data;
                const message = Object.keys(errors)
                    .map((item) => `<p>${standardParams?.[item]}: ${errors[item]}</p>`)
                    .join(',');

                showMessagePopUp({
                    visible: true,
                    type: 'error',
                    message
                });
            }
        }
    };
}

export function deleteOrder(orderId) {
    return async (dispatch) => {
        const url = `${endpoints.ORDER + orderId}/`;
        const config = {
            method: 'delete'
        };

        try {
            await axios(url, config);
            dispatch(getOrders());
        } catch (error) {
            dispatch(orderSlice.actions.hasError(error?.response?.data));
        }
    };
}

export function createSentToVo(orderId, fallback) {
    return async (dispatch) => {
        const url = `${endpoints.ORDER + orderId}/sent-to-vo/`;
        // const url = `/${orderId}/sent-to-vo/`;
        const config = {
            method: 'post'
        };

        dispatch(orderSlice.actions.startingSentVo());

        try {
            await axios(url, config);
            dispatch(orderSlice.actions.sentVoSuccess());
            fallback();
        } catch (error) {
            dispatch(orderSlice.actions.sentVoError(error?.response?.data));

            if (error?.response?.status === 400) {
                const errors = error?.response?.data;
                const message = Object.keys(errors)
                    .map((item) => `<p>${standardParams?.[item]}: ${errors[item]}</p>`)
                    .join(',');

                showMessagePopUp({
                    visible: true,
                    type: 'error',
                    message
                });
            }
        }
    };
}

/**
 *
 * @param {Array} listOrder
 * @param {['sent-to-vo', 'production', 'completed']} status
 * @returns
 */
export function createBatchOrder(params, fallback) {
    return async (dispatch) => {
        const url = `${endpoints.ORDER}sent-to-vo/`;
        const config = {
            method: 'post',
            data: params
        };

        dispatch(orderSlice.actions.startingBatchOrder());

        try {
            await axios(url, config);
            dispatch(orderSlice.actions.batchOrderSuccess());
            fallback();
            dispatch(getOrders({ page: 1, page_size: PAGE_SIZE_DEFAULT }));
        } catch (error) {
            dispatch(orderSlice.actions.batchOrderError(error?.response?.data));

            if (error?.response?.status === 400) {
                const errors = error?.response?.data;
                const message = Object.keys(errors)
                    .map((item) => `<p>${standardParams?.[item]}: ${errors[item]}</p>`)
                    .join(',');

                showMessagePopUp({
                    visible: true,
                    type: 'error',
                    message
                });
            }
        }
    };
}

export function createBatchProduction(params, fallback) {
    return async (dispatch) => {
        const url = `${endpoints.BULK_PRODUCTION}`;
        const config = {
            method: 'post',
            data: params
        };

        dispatch(orderSlice.actions.startingBatchOrder());

        try {
            await axios(url, config);
            dispatch(orderSlice.actions.batchOrderSuccess());
            fallback();
            dispatch(getOrders({ page: 1, page_size: PAGE_SIZE_DEFAULT }));
        } catch (error) {
            dispatch(orderSlice.actions.batchOrderError(error?.response?.data));

            if (error?.response?.status === 400) {
                const errors = error?.response?.data;
                const message = Object.keys(errors)
                    .map((item) => `<p>${standardParams?.[item]}: ${errors[item]}</p>`)
                    .join(',');

                showMessagePopUp({
                    visible: true,
                    type: 'error',
                    message
                });
            }
        }
    };
}

export function createBatchCompleted(params, fallback) {
    return async (dispatch) => {
        const url = `${endpoints.BULK_COMPLETED}`;
        const config = {
            method: 'post',
            data: params
        };

        dispatch(orderSlice.actions.startingBatchOrder());

        try {
            await axios(url, config);
            dispatch(orderSlice.actions.batchOrderSuccess());
            fallback();
            dispatch(getOrders({ page: 1, page_size: PAGE_SIZE_DEFAULT }));
        } catch (error) {
            dispatch(orderSlice.actions.batchOrderError(error?.response?.data));

            if (error?.response?.status === 400) {
                const errors = error?.response?.data;
                const message = Object.keys(errors)
                    .map((item) => `<p>${standardParams?.[item]}: ${errors[item]}</p>`)
                    .join(',');

                showMessagePopUp({
                    visible: true,
                    type: 'error',
                    message
                });
            }
        }
    };
}

export default orderSlice.reducer;
