import { createSlice } from '@reduxjs/toolkit';
import endpoints from 'utils/endpoints';
import axios from 'utils/axios';
import showMessagePopUp from 'ui-component/messagePopUp';

const initialState = {
    isLoading: false,
    error: null,
    listOfLens: {
        count: 0,
        results: [],
        next: null
    },
    viewLens: null
};

const lensGroupSlice = createSlice({
    name: 'lens-group',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        getLensGroupsSuccess: (state, action) => {
            state.isLoading = false;
            if (action.payload.isInfinite) {
                state.listOfLens.results = state.listOfLens.results.concat(...action.payload.data.results);
                state.listOfLens.count = action.payload.data.count;
                state.listOfLens.next = action.payload.data.next;
            } else {
                state.listOfLens = action.payload.data;
            }
        },
        getGroupSuccess: (state, action) => {
            state.isLoading = false;
            state.viewLens = action.payload;
        },
        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        }
    }
});

export const actions = lensGroupSlice.actions;

export function getLensGroups(params) {
    return async (dispatch) => {
        const url = endpoints.LENS_GROUP;
        const config = {
            method: 'get',
            params: {
                offset: params?.offset,
                limit: params?.limit,
                production_type: params?.production_type,
                lens_type: params?.lens_type,
                search: params?.search || null
            }
        };

        dispatch(actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(actions.getLensGroupsSuccess({ data: response, isInfinite: params?.infinite }));
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function getLensGroup(lensId) {
    return async (dispatch) => {
        const url = `${endpoints.LENS_GROUP + lensId}/`;
        const config = {
            method: 'get'
        };

        dispatch(actions.startLoading());

        try {
            const response = await axios(url, config);
            dispatch(actions.getGroupSuccess(response));
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function createLensGroup(data, navigate) {
    return async (dispatch) => {
        const url = endpoints.LENS_GROUP;
        const config = {
            method: 'post',
            data
        };

        dispatch(actions.startLoading());

        try {
            await axios(url, config);
            dispatch(actions.stopLoading());
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Lens Group berhasil ditambahkan',
                buttonText: 'Kembali ke halaman Master Lens Group'
            });
            navigate('/admin/master-data/lens-group', { replace: true });
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function ubahLensGroup(lensId, data, navigate) {
    return async (dispatch) => {
        const url = `${endpoints.LENS_GROUP + lensId}/`;
        const config = {
            method: 'put',
            data
        };

        try {
            await axios(url, config);
            dispatch(actions.stopLoading());
            showMessagePopUp({
                visible: true,
                type: 'success',
                message: 'Lens Group berhasil diubah',
                buttonText: 'Kembali ke halaman Master Lens Group'
            });
            navigate('/admin/master-data/lens-group', { replace: true });
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
        }
    };
}

export function removeLensGroup(lensId, callback) {
    return async (dispatch) => {
        const url = `${endpoints.LENS_GROUP + lensId}/`;
        const config = {
            method: 'delete'
        };

        try {
            await axios(url, config);
            callback('Coating berhasil dihapus', null);
        } catch (error) {
            dispatch(actions.hasError(error?.response?.data));
            callback(null, error?.response?.data);
        }
    };
}

export default lensGroupSlice.reducer;
